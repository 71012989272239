<template>
    <div style="margin:0 auto">
    <!--   <div class="tit1">电动车合格证书</div> -->
    <div class='tit1'>电动自行车合格证查询平台</div>
    <table class="m-table frq" v-if="cerdata !=null" style="line-height: 12px;table-layout:fixed; word-break:break-all; word-wrap:break-all;" align='left' border="0">
        <tbody>
            <tr>
                <td width="40%" style="font-size:14px;font-weight: bold;" colspan="3">电动自行车产品合格证</td>
                <th width="60%"  colspan="4">产品合格证编号：{{cerdata.certificateno}}</th>
            </tr>
            <tr>
                <td style="text-align:center;font-size:14px;font-weight: bold;" colspan="3">第一部分车辆总体信息</td>
                <th style="text-align: center;font-size:14px;font-weight: bold;" colspan="4">第二部分产品合格证参数</th>
            </tr>
            <tr>
                <td  colspan="3">0.0 整车编码：{{cerdata.vehicleNo}}</td>
                <th rowspan="8"  colspan="4" align="center">
                    <div style="margin:0 auto;display:flex;justify-content:center;align-items:center;">
                        <img alt="" :src="'/api/files/get?imgpath='+cerdata.picchinaaddr" style="height:280px;width:70%;"/>
                    </div>
                </th>
            </tr>
            <tr><td colspan="3">0.1 车辆制造商：{{cerdata.manufacturer}}</td></tr>
            <tr><td colspan="3">0.2 生产企业名称：{{cerdata.producer}}</td></tr>
            <tr><td colspan="3">0.3 生产企业地址：{{cerdata.produceraddr}}</td></tr>
            <tr><td colspan="3">0.4 车辆中文商标：{{cerdata.cnbrand==null?'':cerdata.cnbrand}}</td></tr>
            <tr><td colspan="3">0.5 车辆英文商标：{{(cerdata.enbrand==null || cerdata.enbrand == 'null')?'':cerdata.enbrand}}</td></tr>
            <tr><td colspan="3">0.6 产品型号：{{cerdata.modelno}}</td></tr>
            <tr><td colspan="3">0.7 驱动方式：{{cerdata.drivetype}}</td></tr>
            <tr>
                <td colspan="3">0.8 长×宽×高(mm×mm×mm)：{{cerdata.bodysize}}</td>
                <th rowspan="2">1.1 电动机生产企业</th>
                <th rowspan="2">{{cerdata.motorproducer}}</th>
                <th rowspan="2">1.2电动机型号</th>
                <th rowspan="2">{{cerdata.motormodel}}</th>
            </tr>
            <tr>
                <td colspan="3">0.9 前后轮中心距(mm)：{{cerdata.wheeltrack}}</td>
            </tr>
            <tr>
                <td colspan="3">0.10 装配完整的电动自行车的整车质量(kg)：{{cerdata.totalweight}}</td>
                <th>1.3 电动机型式</th>
                <th>{{cerdata.motortype}}</th>
                <th>1.4额定转速(r/min)</th>
                <th>{{cerdata.rotationspeed}}</th>
            </tr>
            <tr>
                <td colspan="3">0.11 最高设计车速(km/h)：{{cerdata.maxspeed}}</td>
                <th>1.5额定连续输出功率(W)</th>
                <th>{{cerdata.outputpower}}</th>
                <th>1.6额定电压(V)</th>
                <th>{{cerdata.voltage}}</th>
            </tr>
            <tr>
                <td colspan="3">0.12 铭牌固定位置：{{cerdata.cardposition}}</td>
                <th>2.1控制器生产企业</th>
                <th>{{cerdata.controllerproducer}}</th>
                <th>2.2控制器型号</th>
                <th>{{cerdata.controllerno}}</th>
            </tr>
            <tr>
                <td colspan="3">0.13 车架上车辆编码的位置：{{cerdata.codeposition}}</td>
                <th>2.3欠压保护值(V)</th>
                <th>{{cerdata.undervoltage}}</th>
                <th>2.4过流保护值(A)</th>
                <th>{{cerdata.overcurrent}}</th>
            </tr>
            <tr>
                <td colspan="3">0.14 电动机编码：{{cerdata.motorNo}}</td>
                <th>3.1蓄电池类型</th>
                <th>{{cerdata.celltype}}</th>
                <th>3.2蓄电池生产企业</th>
                <th>{{cerdata.cellproducer}}</th>
            </tr>
            <tr>
                <td colspan="3">0.15 续行里程(km)：{{cerdata.driverange}}</td>
                <th>3.3蓄电池容量(Ah)</th>
                <th>{{cerdata.cellvolume}}</th>
                <th>3.4蓄电池型号</th>
                <th>{{cerdata.cellno}}</th>
            </tr>
            <tr>
                <td colspan="3">0.16 百公里电耗(kW.h/100km)：{{cerdata.powerconsumption}}</td>
                <th>4.1前轮轮胎规格</th>
                <th>{{cerdata.frontwheel}}</th>
                <th>4.2后轮轮胎规格</th>
                <th>{{cerdata.backwheel}}</th>
            </tr>
            <tr>
                <td colspan="3">0.17.1 CCC证书编号：{{cerdata.cccno}}</td>
                <th rowspan="5">5备注</th>
                <th rowspan="5">{{(cerdata.note==null || cerdata.note == 'null')?'':cerdata.note}}</th>
                <th colspan="2" rowspan="5" style="align:left;valign:center">

                </th>
            </tr>
            <tr><td colspan="3">0.17.2 CCC证书版本号：{{cerdata.cccver}}</td></tr>
            <tr><td colspan="3">0.17.3 CCC证书发证日期：{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td></tr>
            <tr><td colspan="3">0.18 车辆制造日期：{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td></tr>
            <tr>
                <td colspan="3">0.19 产品名称：{{cerdata.productname}}</td>
                <td colspan="3"></td>
            </tr>
            <tr>
                <td colspan="3">0.20 车辆颜色：{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>
                <th colspan="4" v-if="cerdata.standardtype == 1">本产品经过检验，符合GB 17761-2018《电动自行车安全技术规范》的要求，特此证明。</th>
            </tr>
            </tbody>
    </table>
    
    </div>
</template>

<script>
    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
    // 例子： 
    // (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
    // (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
    Date.prototype.Format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份 
            "d+": this.getDate(), //日 
            "H+": this.getHours(), //小时 
            "m+": this.getMinutes(), //分 
            "s+": this.getSeconds(), //秒 
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
            "S": this.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    export default {
        name:'CertificateShow',
        props:['cerprintdata'],
        data(){
            return {
                cerdata:null,
                ccclist:this._self.$root.$store.state.CccList,
            }
        },
        methods:{
            refreshCcclist(){
                this.$axios({
                        method:'post',
                        url:"/api/ccc/query",
                        data:null,
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        if(res.data.resultCode ==0){
                            var rspdata = res.data.data
                            this.ccclist =rspdata
                            this.$store.commit('changeCccList',this.ccclist)
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                    }.bind(this));   
            },
        },
        watch:{
            cerprintdata (n){
            },
            cerdata (n){

            }
        },
        mounted(){
            this.cerdata =this.cerprintdata
            if(this.cerprintdata !=null){
                if(this.cerprintdata.cer.picshanghaiaddr !=null && this.cerprintdata.cer.picshanghaiaddr !='null'){
                    this.cerprintdata.cer.picshanghaiaddr = encodeURIComponent(this.cerprintdata.cer.picshanghaiaddr)        
                }
                if(this.cerprintdata.cer.picchinaaddr !=null && this.cerprintdata.cer.picchinaaddr !='null'){
                    this.cerprintdata.cer.picchinaaddr = encodeURIComponent(this.cerprintdata.cer.picchinaaddr)        
                }
                this.cerdata = this.extend({},this.cerprintdata.cer,this.cerprintdata.vehicle)
            }
            console.log(this.cerdata)
            console.log(this.$route)
            var vin =this.$route.query["vinCode"]
            if(vin==null){
                vin=this.$route.params["vin"]
            }
            console.log(vin)
            if(this.ccclist ==null){
                this.refreshCcclist()
            }

            if(vin !=null){
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/querycerdetail",
                    data:{vehicleno:vin},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        //this.afterAddVehicle()
                        console.log(res.data.data)
                        if(res.data.data.picshanghaiaddr=='null' ||res.data.data.picshanghaiaddr==''){
                            res.data.data.picshanghaiaddr=null
                        }
                        else{
                            res.data.data.picshanghaiaddr = encodeURIComponent(res.data.data.picshanghaiaddr)
                            console.log(res.data.data.picshanghaiaddr)
                        }
                        if(res.data.data.picchinaaddr=='null' ||res.data.data.picchinaaddr==''){
                            res.data.data.picchinaaddr=null
                        }
                        else{
                            res.data.data.picchinaaddr = encodeURIComponent(res.data.data.picchinaaddr)
                            console.log(res.data.data.picchinaaddr)
                        }
                        this.cerdata = res.data.data
                        
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));    
            }
        }
    }
</script>

<style type="text/css" scoped>
/* reset */
html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu{margin:0;padding:0;}
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details{display:block;}
table{border-collapse:collapse;border-spacing:0;}
caption,th{text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr{border:0;}
i,cite,em,var,address,dfn{font-style:normal;}
[hidefocus],summary{outline:0;}
li{list-style:none;}
h1,h2,h3,h4,h5,h6,small{font-size:100%;}
sup,sub{font-size:83%;}
pre,code,kbd,samp{font-family:inherit;}
q:before,q:after{content:none;}
textarea{overflow:auto;resize:none;}
label,summary{cursor:default;}
a,button{cursor:pointer;}
h1,h2,h3,h4,h5,h6,em,strong,b{font-weight:bold;}
del,ins,u,s,a,a:hover{text-decoration:none;}
body,textarea,input,button,select,keygen,legend{font:1px/1.14 arial,\5b8b\4f53;color:#333;outline:0;}
.frq tr td{border-style: none;}
body{}
a,a:hover{color:#333;}

.m-table{line-height:1.0;width:100%}
.m-table th,.m-table td{padding:15px;border:1px solid #ddd;word-wrap: break-word;font-size:12px;text-align: left}
.m-table th{}

body{font-family: SimSun;}

.tit1{text-align: left;padding: 20px 0px;font-size: 16px;font-weight: bold;border:1px solid #bfbfbf;;background: #bbeff5;height: 18px;line-height: 12px;}
.tip{display: none;}
</style>